import React, { useEffect, useState } from "react";
import Title from "../../components/Title";
import { HeroSection } from "../../components/HeroSection";
import CollapsedMenu from "../../components/CollapsedMenu";
import CollapsedMenuStrapi from "../../components/CollapsedMenuStrapi";
import { getData } from "../../common/strapiUrl";

const FAQs = [
  {
    Title: "What are the requirements to working with Trip Merchant?",
    detail: null,
    list: [
      "We have a specific criteria that we look for in order to provide this complimentary branded travel website.",
      "You must be a Membership-Based Organization or an Employer with 500 or more members/employees.",
      "You must have an existing website for your organization",
      "Please contact us to schedule a demo",
    ],
  },
  {
    Title: "Are there any hidden costs?",
    detail: null,
    list: ["None! We know what you’re thinking; pretty impressive!"],
  },
  {
    Title: "Why is tHe website password protected?",
    detail: null,
    list: [
      "We want to ensure that only members/employees are able to access the travel website (*available to friends and family to also receive the travel benefits when traveling with a member/employee that has access)",
      "Group departures are exclusively for members of the organization to be able to see",
      "It enables us to be able to publish exclusive offers that aren’t available to the general public",
    ],
  },
  {
    Title: "What type of travel experiences do you offer?",
    detail:
      "We recognize that many travelers have a variety of interests. We feature an array of travel styles such as:",
    list: [
      " Ocean Cruises",
      "River Cruises",
      "Rail Journeys",
      "Guided Vacations",
      " Adventure & Active Travel",
      "And more!",
    ],
  },
  {
    Title: "Do you offer a loyalty program",
    detail: null,
    list: [
      "Yes! Anytime someone books a trip that is featured on the site they will receive loyalty dollars and their own loyalty account.",
      "All the details of the loyalty program are featured on your dedicated travel website for members/employees to view.",
      "These loyalty dollars are over and above any existing loyalty program that are offered by any of our travel partners.",
    ],
  },
  {
    Title:
      "My organization has a lot of single travellers. How are you able to accommodate these members?",
    detail: null,
    list: [
      "We have a section devoted to solo travel (where they offer single friendly programs with no single supplement)",
      "By offering group travel opportunities on the website, those single travellers can request a travel companion by noting that on their submission form",
    ],
  },
  {
    Title:
      "Are you able to do presentations for our membership at a General Meeting or Conference?",
    detail: null,
    list: [
      "Yes we are; if requested with enough notice. We will make every effort to attend in person.",
      "We’re also able to do presentations via a webinar (online presentation)",
    ],
  },
  {
    Title: "Can our members share their own travel experiences?",
    detail: null,
    list: [
      "Yes, absolutely!",
      "We have an online submission form for members to share these experiences and we will publish them so that other members will also be able enjoy them.",
    ],
  },
  {
    Title:
      "What responsibilities do we have working with a Trip Merchant provided travel website?",
    detail: null,
    list: [
      "We build, host and manage the travel website entirely on your behalf. This includes updating the site with new offers, group departures, travel e-newsletters, travel news & tips, videos and other travel related services.",
      "We will continuously upgrade the site with new technology to always ensure a seamless user experience.",
      "Your only responsibility is to have a link from your main website to your dedicated Trip Merchant travel website.",
      "Also to make your membership/employees aware of it through your own internal communications.",
      "Trip Merchant bears considerable expense in providing and maintaining these travel websites. In order for Trip Merchant to recoup its investment the travel website needs to actively used by the membership.",
    ],
  },
];

const FAQ = () => {
  const [active, setActive] = useState("");
  const [data, setData] = useState();

  useEffect(() => {
    getData("/q-a").then((res) => {
      // console.log(res?.data?.attributes, "res");
      setData(res?.data?.attributes);
    });
  }, []);
  return (
    <>
      <Title title={"Trip Merchant | FAQ"} />
      <HeroSection
        heading={"FAQ"}
        title={"Frequently Asked Questions"}
        image={require("../../assets/images/whoweare.jpg")}
        bannerClass={"justify-content-center text-center"}
        Tag={"h4"}
        color="#F25926"
      />
      {data?.associations?.Title?.length > 0 &&
        data?.associations?.FAQ?.length > 0 && (
          <div className="container my-3">
            <div style={{ color: "#F25926" }} className="text-center h1 py-2">
              {data?.associations?.Title}
            </div>
            {data?.associations?.FAQ.map((data, index) => (
              <CollapsedMenuStrapi
                data={data}
                key={index}
                className="reward-loyalty-program"
                centered={true}
                setActive={setActive}
                active={active}
                font="font-lg"
              />
            ))}
          </div>
        )}
      {data?.consumers?.Title?.length > 0 &&
        data?.consumers?.FAQ?.length > 0 && (
          <div className="container my-3">
            <div style={{ color: "#F25926" }} className="text-center h1 py-2">
              {data?.consumers?.Title}
            </div>
            {data?.consumers?.FAQ.map((data, index) => (
              <CollapsedMenuStrapi
                data={data}
                key={index}
                className="reward-loyalty-program"
                centered={true}
                setActive={setActive}
                active={active}
                font="font-lg"
              />
            ))}
          </div>
        )}
    </>
  );
};

export default FAQ;
