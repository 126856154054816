import React, { useState } from "react";
import { TB, AI } from "../../../common/Icons";
import logo from "../../../assets/images/light-logo.png";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import WebRoutes from "../../../pages/WebRoutes";
import NavBar from "./Navbar";

const TopBar = ({ organizationWebLinks, link, hide }) => {
  const [show, setShow] = useState(false);
  //const { width } = useWindowDimensions();

  return (
    <>
      <div className="top-bar d-flex justify-content-between align-items-center pt-2 pb-1 px-md-4 px-sm-2 px-1 bg-transparent">
        <div className="d-none d-lg-block">
          <Link to={organizationWebLinks ? "/" : WebRoutes.tripmerchant}>
            <img
              src={logo}
              alt="tripMerchant"
              className="logo"
            /* style={{
              width: width > 1050 ? "220px" : "160px",
            }} */
            />
          </Link>
        </div>
        <div className="d-md-block d-lg-none">
          <div className="d-flex justify-content-between">
            <div className="me-2 menu-icon display-menu text-unselectable border-radius">
              <div
                className="justify-content-between p-1 border border-1 border-white border-radius"
                onClick={() => setShow(true)}
              >
                <AI.AiOutlineMenu className="user-icon" size={20} />
              </div>
            </div>
            <Link to={organizationWebLinks ? "/" : WebRoutes.tripmerchant}>
              <img
                /*  style={{
                  width: width > 1050 ? "220px" : "140px",
                }} */
                className="logo"
                src={logo}
                alt="tripMerchant"
              />
            </Link>
          </div>
        </div>
        {hide ? null : <NavBar organizationWebLinks={organizationWebLinks} />}

        <div className="d-flex justify-content-between">
          <div className="mx-2">
            <Link to={link ? WebRoutes.signup : WebRoutes.signin}>
              <div className="button justify-content-between align-items-center">
                <TB.TbUserCircle className="user-icon" />
                <span className="ms-1">{link ? "Register" : "Log In"}</span>
              </div>
            </Link>
          </div>
        </div>
      </div >
      <MobileMenu
        organizationWebLinks={organizationWebLinks}
        show={show}
        hide={(e) => setShow(!e)}
      />
    </>
  );
};

export default TopBar;
