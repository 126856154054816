import React from "react";
import Title from "../../components/Title";
import { HeroSection } from "../../components/HeroSection";
import CollapsedMenu from "../../components/CollapsedMenu";
import { useEffect, useState } from "react";
import { strapiUrlApi } from "../../common/strapiUrl";

const getData = async (url, filter) => {
  const response = await fetch(
    `${strapiUrlApi}${url}?populate=*&[filters][Testimonial]=${filter}`
  );
  const data = await response.json();
  return data;
};

let menuData = {
  title: "Title",
  detail: "Hello Trip Merchant",
};

const Testimonials = () => {
  const [members, setMembers] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    getData("/testimonials", "members").then((res) => {
      setMembers(res?.data);
    });
    getData("/testimonials", "partners").then((res) => {
      setPartners(res?.data);
    });
  }, []);

  return (
    <>
      <Title title={"Trip Merchant | Testimonials"} />
      <HeroSection
        heading={"Testimonials"}
        //title={"Frequently Asked Questions"}
        image={require("../../assets/images/whoweare.jpg")}
        bannerClass={"justify-content-center text-center"}
        //Tag={"h4"}
        color="#F25926"
      />
      <div className="container my-3">
        <h3 className="text-center mt-2">SEE WHAT MEMBERS ARE SAYING BELOW:</h3>
        {members.map((data, index) => {
          console.log("ahmad", data.attributes.comment);
          menuData = {
            title: data.attributes.Heading,
            detail: data.attributes.comment,
          };
          return <CollapsedMenu data={menuData} key={index} />;
        })}
        <h3 className="text-center mt-2 pt-2">
          SEE WHAT OUR PARTNERS ARE SAYING BELOW:
        </h3>
        {partners.map((data, index) => {
          menuData = {
            title: data.attributes.Heading,
            detail: data.attributes.comment,
          };
          return <CollapsedMenu data={menuData} key={index} />;
        })}
      </div>
    </>
  );
};

export default Testimonials;
