import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import NewsLetterForm from "./NewsLetterForm";
import WebRoutes from "../pages/WebRoutes";
import { BI, MD } from "../common/Icons";
import PopupAlert from "./PopupAlert";

const footerLinks = [
  {
    title: "About Us",
    action: null,
    link: WebRoutes.WhoWeAre,
  },
  {
    title: "Why Trip Merchant",
    action: null,
    link: WebRoutes.generalSubscription,
  },
  {
    title: "Partner with Us",
    action: null,
    link: WebRoutes.partnerWithUs,
  },
  {
    title: "Loyalty Program",
    action: true,
    link: WebRoutes.generalSubscription,
  },
  // {
  //   title: "Referral Program",
  //   action: true,
  //   link: WebRoutes.generalSubscription,
  // },
  {
    title: "FAQ",
    action: null,
    link: WebRoutes.faq,
  },
  {
    title: "Testimonials",
    action: null,
    link: WebRoutes.testimonials,
  },
  {
    title: "Trip Merchant Journeys",
    action: false,
    link: WebRoutes.tripmerchantJourneys,
  },
  {
    title: "Trip Contests",
    action: true,
    link: WebRoutes.generalSubscription,
  },
  {
    title: "Online Presentations",
    action: true,
    link: WebRoutes.generalSubscription,
  },
  {
    title: "Solo Explorers",
    action: true,
    //link: WebRoutes.soloTraveler,
    link: WebRoutes.generalSubscription,
  },
  {
    title: "Contact Us",
    action: null,
    link: WebRoutes.ContactUs,
  },
  {
    title: "Privacy Policy",
    action: null,
    link: WebRoutes.privacy,
  },
  {
    title: "Terms and Conditions",
    action: null,
    link: WebRoutes.terms,
  },
];

const ListMap = ({ data }) => {
  const [modalData, setModalData] = useState({
    title: null,
    show: false,
    navigate: null,
  });
  const navigate = useNavigate();

  return (
    <>
      <li
        onClick={() =>
          data.action
            ? setModalData({
                title: data.title,
                navigate: data.link,
                show: true,
              })
            : navigate(data.link)
        }
      >
        {data.title}
      </li>
      <PopupAlert
        state={modalData}
        close={(e) =>
          setModalData({
            title: null,
            show: e,
            navigate: null,
          })
        }
      />
    </>
  );
};

const Footer = () => {
  return (
    <section id={"newsLetterSignUp"}>
      <div className="footer bg-black">
        <div className="container bg-black">
          <div className="container-padding">
            <Row lg="12">
              <Col lg="3" sm="12">
                <div className="container col1">
                  <img
                    src={require("../assets/images/light-logo.png")}
                    alt="trip-merchant"
                  />
                  <div className="content-center my-2 mt-3">
                    {/* eslint-disable-next-line */}
                    <a className="d-flex align-items-center font-600 fs-6">
                      <BI.BiSupport size={20} />
                      <span className="ms-2">1-800-481-9739</span>
                    </a>
                    {/* eslint-disable-next-line */}
                    <a className="d-flex align-items-center font-600 fs-6">
                      <MD.MdOutlineMailOutline size={20} />
                      <span className="ms-2">info@tripmerchant.com</span>
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="5" sm="12">
                <div className="container col2">
                  <h2>Quick Links</h2>
                  <Row className="list pt-3">
                    <ul>
                      {footerLinks.map((data, index) => (
                        <ListMap key={index} data={data} />
                      ))}
                    </ul>
                  </Row>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="container col3 mb-2">
                  <h2>Subscribe to Newsletter</h2>
                </div>
                <NewsLetterForm />
              </Col>
            </Row>
          </div>
          <hr />
          <div className="d-flex justify-content-center align-items-center text-center pb-3">
            Trip Merchant &copy; {new Date().getFullYear() || "2022"}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
